<script setup lang="ts">
import { getPriceText } from '~/helpers/priceHelper'
import type { HousesCoordinates } from 'lc-services/types'

const { hitMap } = defineProps<{
  hitMap: HousesCoordinates['features'][0]['properties']
}>()
defineEmits<{ close: []; 'track-house': [houseId: number] }>()

const { isMobile } = useBreakpoint()
const localePath = useLocalePath()
const { t, locale } = useI18n()
const { currency } = useUser()

const { setCurrentHouseFavorite } = useSearchWishlist()
const { startDate, endDate } = useSearchDateFilters()

const details = ['capacity', 'bedrooms', 'bathrooms'] as const
</script>

<template>
  <div class="max-w-80">
    <NuxtLink
      :href="
        localePath({
          name: 'house-slug',
          params: {
            slug: hitMap.slug,
          },
        })
      "
      class="hover:no-underline"
      :target="isMobile ? '_self' : '_blank'"
      @click="$emit('track-house', hitMap.houseId)"
    >
      <div class="flex h-full flex-col justify-between">
        <div class="relative">
          <VirtualBaseCarousel
            :navigation="true"
            :pagination="true"
            :slides="hitMap.photos"
            class="h-[213px] rounded-t"
            :class-name="`house-card__map-${hitMap.houseId}`"
          >
            <template #slider-content="{ slide }">
              <div class="swiper-slide--overlay h-full">
                <BaseNuxtImg :height="163" :src="slide" :width="260" />
              </div>
            </template>
            <template #navigation>
              <div
                class="swiper--prev absolute left-4 top-1/2 z-10 -translate-y-1/2 rounded-full bg-gray-700/70 p-2 duration-500 ease-in-out group-hover:opacity-100"
                @click.stop.prevent
              >
                <BaseIcon :size="1" color="text-white" name="chevronLeft" />
              </div>
              <div
                class="swiper--next absolute right-4 top-1/2 z-10 -translate-y-1/2 rounded-full bg-gray-700/70 p-2 duration-500 ease-in-out group-hover:opacity-100"
                @click.stop.prevent
              >
                <BaseIcon :size="1" color="text-white" name="chevronRight" />
              </div>
              <VirtualBaseCarouselPaginationV1 class="!bottom-2" />
            </template>
          </VirtualBaseCarousel>
          <div class="absolute right-3 top-3 z-50 flex items-center gap-2">
            <WishlistToggle
              v-slot="slotProps"
              :house-id="hitMap.houseId"
              :house-name="hitMap.houseName"
              @callback-click="
                setCurrentHouseFavorite({
                  id: hitMap.houseId,
                  name: hitMap.houseName,
                  iconicCollection: true,
                  publicPrice: hitMap.price || undefined,
                })
              "
            >
              <HouseWishlistButton
                class="rounded-full bg-white p-2 text-gray-700"
                :is-active="slotProps.active"
                :size="1"
              />
            </WishlistToggle>
            <div
              class="rounded-full bg-white p-1.5 text-gray-700"
              @click.prevent.stop="$emit('close')"
            >
              <BaseIcon name="close" :size="1.2" />
            </div>
          </div>
        </div>
        <div class="flex h-full flex-col justify-between p-4 text-left">
          <h6 class="m-0 text-base leading-6 text-gray-700">
            {{ hitMap.houseName }}
          </h6>
          <div class="text-md text-gray-500">
            <span
              v-for="(detail, i) in details"
              :key="i"
              :class="`
                after:content-[''] after:w-0.5 after:h-0.5 after:mx-2
                after:inline-block after:align-middle
                after:bg-gray-600 after:rounded-full
                last:after:hidden
              `"
            >
              {{ hitMap[detail] }}
              {{ $t(`house.${detail}Key`, hitMap[detail]) }}
            </span>
          </div>
          <div class="mt-2 text-md font-bold text-gray-700">
            {{
              getPriceText(
                { t, locale },
                {
                  display_prices: hitMap.displayPrice,
                  publicPrice: hitMap.price,
                  min_week_price: hitMap.minPrice,
                  max_week_price: hitMap.maxPrice,
                },
                currency,
                startDate && endDate ? [startDate, endDate] : null,
              )
            }}
          </div>
        </div>
      </div>
    </NuxtLink>
  </div>
</template>
